// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-work-jsx": () => import("./../src/templates/work.jsx" /* webpackChunkName: "component---src-templates-work-jsx" */),
  "component---src-templates-work-list-jsx": () => import("./../src/templates/work-list.jsx" /* webpackChunkName: "component---src-templates-work-list-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */)
}

